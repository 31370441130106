<template>
	<div class="content">
		<div class="card">
			<div class="card-content">
				<div class="content">
					<h2>Máme otevřeno!</h2>
					<table>
						<tr>
							<td>Pondělí</td>
							<td>18:00 - 21:00</td>
						</tr>
						<tr>
							<td>Úterý</td>
							<td>18:00 - 21:00</td>
						</tr>
						<tr>
							<td>Středa</td>
							<td>18:00 - 21:00</td>
						</tr>
						<tr>
							<td>Čtvrtek</td>
							<td>18:00 - 21:00</td>
						</tr>
						<tr>
							<td>Pátek</td>
							<td>18:00 - 21:00</td>
						</tr>
					</table>
					<p>Sobota a neděle nejsou problém po předchozí domluvě.</p>
				</div>
			</div>
		</div>
		<div class="card">
			<div class="card-content">
				<div class="content">
					<h2>Vítej na webu <b>smashedgarage s.r.o.</b></h2>
					<p>
						Jmenuju se <a href="https://kosekjan.eu">Jan Kosek</a> a založil jsem smashedgarage s.r.o. abych byl zase o kus blíž svému snu.
						Tím je mít svůj vlastní autoservis a poskytovat kvalitně odvedenou práci za normální cenu. K tomu je ale ještě dlouhá cesta, protože jsem
						vystudovaný "ajťák" a proto zatím oficiálně můžu poskytovat pouze <b>pneuservis</b>, výmněny filtrů a olejů.
					</p>
					<p>
						Aktuálně pracuji na zprovoznění dílny, kterou dělám ze stodoly a je na ní spoustu práce. Proto zatím netuším, kdy bude mít <b>smashedgarage</b>
						oficiálně otevřeno.
					</p>
				</div>
				<youtube-card title="" text="" height="350" width="550" videoId="rb63EOP-GbY" />
			</div>
		</div>
	</div>
</template>

<script>
import YoutubeCard from '@/components/ui/youtubeCard/youtubeCard.vue';

export default {
	name: 'Updates',
	components: {
		YoutubeCard
	}
}
</script>